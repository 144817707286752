import {
  COUNTER_API,
  WORKSPACE_API,
  WORKSPACE_URL,
  METHODS,
} from "../../constants";

import ClearIcon from "@mui/icons-material/Clear";
import CachedIcon from "@mui/icons-material/Cached";
import RefreshDialog from "../Dialogs/RefreshDialog";
import DateDialog from "../Dialogs/DateDialog";
import PullHwReservationDialog from "../Dialogs/PullHwReservationDialog";
import RoomsDialog from "../Dialogs/RoomsDialog";
import moment from "moment";

const { GET, POST, DELETE, PATCH } = METHODS;

export const getCleanupOptions = ({
  propertyId,
  channexPropertyID,
  myaPropertyID,
  hostelworldPropertyID,
  userProfile,
}) => {
  const isChannexEnabled = !!channexPropertyID;
  const isHWEnabled = !!hostelworldPropertyID;
  const isMYAEnabled = !!myaPropertyID;

  return [
    {
      name: "ca.buttons.property.label",
      buttons: [
        {
          name: "ca.buttons.property.cleanup.name",
          buttonLabel: "ca.buttons.property.cleanup.dialogButtonLabel",
          dialogWarning: "ca.buttons.property.cleanup.dialogWarning",
          icon: <CachedIcon />,
          getDialogComponent: () => <DateDialog />,
          getUrl: ({ startDate, endDate }) =>
            `${COUNTER_API}/api/admin/cleanup/property/${propertyId}?startDate=${startDate}&endDate=${endDate}`,
        },
      ],
    },
    {
      name: "ca.buttons.navigate.label",
      buttons: [
        {
          name: "ca.buttons.navigate.counter.name",
          buttonLabel: "ca.buttons.navigate.counter.dialogButtonLabel",
          icon: <CachedIcon />,
          method: PATCH,
          getDialogComponent: () => <RefreshDialog />,
          getParams: ({ propertyId }) => ({
            propertyID: propertyId,
            newAdmin: userProfile.id,
          }),
          getUrl: ({ propertyId }) =>
            `${WORKSPACE_API}/api/admin/spaces/${propertyId}?isAdminRoute=true`,
          successCallback: () => {
            return window.open(WORKSPACE_URL, "_blank");
          },
        },
      ],
    },
    {
      name: "ca.buttons.mappings.label",
      buttons: [
        {
          name: "ca.buttons.mappings.hw.name",
          buttonLabel: "ca.buttons.mappings.hw.dialogButtonLabel",
          icon: <CachedIcon />,
          isHidden: isHWEnabled,
          getDialogComponent: () => (
            <PullHwReservationDialog inputLabel={"HostelWorld Property ID"} />
          ),
          method: POST,
          getParams: ({ propertyNumber }) => ({
            propertyID: propertyId,
            propertyNumber,
          }),
          getUrl: () => `${COUNTER_API}/relay/hostelworld/propertyMap`,
        },
        {
          name: "ca.buttons.mappings.cnx_disconnect.name",
          buttonLabel: "ca.buttons.mappings.cnx_disconnect.dialogButtonLabel",
          dialogTitle: "ca.buttons.mappings.cnx_disconnect.dialogTitle",
          icon: <CachedIcon />,
          isHidden: !isChannexEnabled,
          method: DELETE,
          buttonColor: "error",
          getDialogComponent: () => <RefreshDialog />,
          getUrl: () => `${COUNTER_API}/channex/property/${propertyId}`,
        },
        {
          name: "ca.buttons.mappings.hw_disconnect.name",
          buttonLabel: "ca.buttons.mappings.hw_disconnect.dialogButtonLabel",
          dialogTitle: "ca.buttons.mappings.hw_disconnect.dialogTitle",
          icon: <CachedIcon />,
          isHidden: !isHWEnabled,
          method: DELETE,
          buttonColor: "error",
          getDialogComponent: () => <RefreshDialog />,
          getParams: () => ({
            id: propertyId,
          }),
          getUrl: () => `${COUNTER_API}/relay/hostelworld/propertyMap`,
        },
      ],
    },
    {
      name: "ca.buttons.full_refresh.label",
      buttons: [
        {
          name: "ca.buttons.full_refresh.full_cleanup.name",
          buttonLabel: "ca.buttons.full_refresh.full_cleanup.dialogButtonLabel",
          isHidden: !isHWEnabled,
          icon: <CachedIcon />,
          getDialogComponent: () => <DateDialog />,
          getParams: ({ startDate, endDate }) => ({
            startDate,
            endDate,
          }),
          getUrl: () =>
            `${COUNTER_API}/intercom/hostelworld/fullRefresh/${propertyId}?isAdminRoute=true`,
        },
        {
          name: "ca.buttons.full_refresh.restrictions_hw.name",
          buttonLabel:
            "ca.buttons.full_refresh.restrictions_hw.dialogButtonLabel",
          icon: <CachedIcon />,
          isHidden: !isHWEnabled,
          getDialogComponent: () => <RefreshDialog />,
          getUrl: () =>
            `${COUNTER_API}/intercom/restrictions/fullRefresh/hostelworld/${propertyId}?isAdminRoute=true`,
        },
        {
          name: "ca.buttons.full_refresh.refresh_channex.name",
          buttonLabel:
            "ca.buttons.full_refresh.refresh_channex.dialogButtonLabel",
          icon: <CachedIcon />,
          isHidden: !isChannexEnabled,
          getDialogComponent: () => <RefreshDialog />,
          getUrl: () =>
            `${COUNTER_API}/channex/property/fullRefresh/${propertyId}`,
        },
        {
          name: "ca.buttons.full_refresh.refresh_myAllocator.name",
          buttonLabel:
            "ca.buttons.full_refresh.refresh_myAllocator.dialogButtonLabel",
          icon: <CachedIcon />,
          isHidden: !isMYAEnabled,
          getDialogComponent: () => <RefreshDialog />,
          getUrl: () =>
            `${COUNTER_API}/intercom/fullRefresh/${propertyId}/mya?isAdminRoute=true`,
        },
      ],
    },
    {
      name: "ca.buttons.rooms.label",
      buttons: [
        {
          name: "ca.buttons.rooms.reset_capacity.name",
          icon: <CachedIcon />,
          buttonLabel: "ca.buttons.rooms.reset_capacity.dialogButtonLabel",
          buttonColor: "error",
          dialogTitle: "ca.buttons.rooms.reset_capacity.dialogTitle",
          dialogWarning: "ca.buttons.rooms.reset_capacity.dialogWarning",
          getDialogComponent: () => <RoomsDialog />,
          getParams: ({ startDate, endDate }) => ({
            startDate,
            endDate,
          }),
          getUrl: ({ roomTypeId }) =>
            `${COUNTER_API}/intercom/rooms/resetCapacity/${propertyId}/${roomTypeId}?isAdminRoute=true`,
        },
      ],
    },
    {
      name: "ca.buttons.reservations.label",
      buttons: [
        {
          name: "ca.buttons.reservations.clean_reservations.name",
          icon: <ClearIcon />,
          method: PATCH,
          getDialogComponent: () => <DateDialog />,
          buttonLabel:
            "ca.buttons.reservations.clean_reservations.dialogButtonLabel",
          getUrl: ({ startDate, endDate }) =>
            `${COUNTER_API}/api/cleanup/reservations?startDate=${startDate}&endDate=${endDate}&propertyID=${propertyId}`,
        },
        {
          name: "ca.buttons.reservations.pull_hw_reservations.name",
          icon: <CachedIcon />,
          dialogType: "input",
          buttonLabel:
            "ca.buttons.reservations.pull_hw_reservations.dialogButtonLabel",
          method: POST,
          isHidden: !isHWEnabled,
          getDialogComponent: () => <DateDialog />,
          getParams: ({ startDate, endDate }) => ({
            startDate,
            endDate,
            propertyID: hostelworldPropertyID,
          }),
          getUrl: () => `${COUNTER_API}/relay/hostelworld/reservation/pull`,
        },
        {
          name: "ca.buttons.reservations.pull_hw_reservation_by_id.name",
          icon: <CachedIcon />,
          dialogType: "input",
          isHidden: !isHWEnabled,
          buttonLabel:
            "ca.buttons.reservations.pull_hw_reservation_by_id.dialogButtonLabel",
          getDialogComponent: () => (
            <PullHwReservationDialog
              inputLabel={
                "ca.buttons.reservations.pull_hw_reservation_by_id.inputLabel"
              }
            />
          ),
          getUrl: ({ sourceRsvnID }) =>
            `${COUNTER_API}/relay/hostelworld/reservation/pull/sourceRsvnID/${sourceRsvnID}`,
        },
        {
          name: "ca.buttons.reservations.pull_mya_reservation_by_id.name",
          icon: <CachedIcon />,
          dialogType: "input",
          isHidden: !isMYAEnabled,
          buttonLabel:
            "ca.buttons.reservations.pull_mya_reservation_by_id.dialogButtonLabel",
          getDialogComponent: () => (
            <PullHwReservationDialog
              inputLabel={
                "ca.buttons.reservations.pull_mya_reservation_by_id.inputLabel"
              }
            />
          ),
          method: GET,
          getUrl: ({ sourceRsvnID }) =>
            `${COUNTER_API}/relay/myallocator/reservation/${propertyId}/${sourceRsvnID}`,
        },
      ],
    },
    {
      name: "ca.buttons.allocations.label",
      buttons: [
        {
          name: "ca.buttons.allocations.clean_up_orphans.name",
          icon: <CachedIcon />,
          color: "pink",
          method: DELETE,
          getDialogComponent: () => <DateDialog />,
          buttonLabel:
            "ca.buttons.allocations.clean_up_orphans.dialogButtonLabel",
          getUrl: ({ startDate, endDate }) =>
            `${COUNTER_API}/api/allocations/orphans?startDate=${startDate}&endDate=${endDate}&propertyID=${propertyId}`,
        },
        {
          name: "ca.buttons.allocations.clean_up_dummy.name",
          icon: <CachedIcon />,
          color: "pink",
          buttonLabel:
            "ca.buttons.allocations.clean_up_dummy.dialogButtonLabel",
          getDialogComponent: () => <DateDialog />,
          getUrl: ({ startDate, endDate }) =>
            `${COUNTER_API}/api/allocations/fix/dummy?propertyID=${propertyId}&startDate=${startDate}&endDate=${endDate}`,
        },
      ],
    },
    {
      name: "ca.buttons.availability.label",
      buttons: [
        {
          name: "ca.buttons.availability.refresh_availability.name",
          buttonLabel:
            "ca.buttons.availability.refresh_availability.dialogButtonLabel",
          icon: <CachedIcon />,
          getDialogComponent: () => <RefreshDialog />,
          getUrl: () =>
            `${COUNTER_API}/api/availability/fullRefresh/${propertyId}`,
        },
        {
          name: "ca.buttons.availability.remove_duplicate.name",
          buttonLabel:
            "ca.buttons.availability.remove_duplicate.dialogButtonLabel",
          icon: <CachedIcon />,
          getDialogComponent: () => <DateDialog />,
          method: DELETE,
          getUrl: () =>
            `${COUNTER_API}/api/admin/availability/remove-duplicates`,
          getParams: ({ propertyId, startDate, endDate }) => ({
            propertyID: propertyId,
            startDate,
            endDate,
          }),
        },
        {
          name: "ca.buttons.availability.debug.name",
          icon: <CachedIcon />,
          getLinkTo: ({
            startDate = moment().format("YYYY-MM-DD").toString(),
            endDate = moment().add(1, "week").format("YYYY-MM-DD"),
            timezone = "UTC",
          }) => ({
            pathname: "/debugAvailability",
            search: `filter=${JSON.stringify({
              propertyID: propertyId,
              timezone,
              startDate,
              endDate,
            })}`,
          }),
        },
      ],
    },
  ];
};
